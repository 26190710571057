
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core';
import throttle from 'lodash/throttle';
import firebase from 'firebase';

import constants from './app/constants';
import App from './app/app';
import Routes from './app/routes';
import reduxStore, { saveState } from './redux/store';
import loadMessages from './intl/loadMessages';
import theme from './index.theme';
import './index.css';
import firebaseConfig from './firebaseConfig';

// Main renderer
function render() {
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <IntlProvider locale={language} messages={messages}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top',horizontal: 'center',}}>
              <App>
                <Routes />
              </App>
            </SnackbarProvider>
          </BrowserRouter>
        </IntlProvider>
      </MuiThemeProvider>
    </Provider>
    , document.getElementById('root')
  );
};

// Init firebase
firebase.initializeApp(firebaseConfig);  

// Load i18n messages
const { language, messages } = loadMessages();

// Get redux store
const store = reduxStore({ language, version: constants.APP_VERSION });

// Subscribe to redux store
// Note: When some data change in 'store', redux will update the subsribed actions
store.subscribe(render);
store.subscribe(throttle(() => saveState('state', store.getState()), 3000));

// Render the main renderer
render();

