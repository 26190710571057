
import React, { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles, Typography, TextField, Button, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import styles from './employee.css';
import { employeeIn } from '../../redux/actions';
import { findEmployee, allowedEmployee } from '../../service/employee';

const useStyles = makeStyles(styles);

function Employee() {
  const classes = useStyles();
  
  // Local data
  const [ data, setData ] = useState({});

  // Global data
  const dispatch = useDispatch();
  const employee = useSelector(state => state.employee);
  const { enqueueSnackbar } = useSnackbar();

  // Function to get the input data
  function onChange(event) {
    setData({ ...data, [event.target.id]: event.target.value });
  }

  // Function to handle the employee submit 
  async function handleSubmit(event) {
    event.preventDefault();
    if(data != null && typeof(data) != 'undefined' && typeof(data.id) != 'undefined') {
      data.id = data.id.trim().replace(/\s/g, "").toUpperCase();
      const employee = findEmployee(data);
      if(employee == null) { // Not found
        enqueueSnackbar('Error: Employee ID not found: \'' + data.id + '\'', {variant: 'error',});
      } else if(!allowedEmployee(employee)) { // Not allowed
        enqueueSnackbar('Error: Employee not allowed', {variant: 'error',});
      } else { // Employee exists and allowed
        dispatch(employeeIn(employee));
      }
    }  
  };

  // Render the component
  return (
    <div className={classes.root}>
      {!employee && 
        <Box color="primary.contrastText" bgcolor="error.main" className={classes.dash}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField id="id" label="Employee ID" value={data.id || ''} onChange={onChange} />
            <Button type="submit" variant="contained">Check</Button>
          </form>
        </Box>    
      }
      {employee &&
        <Box color="primary.contrastText" bgcolor="success.main" className={classes.dash}>
          <Typography className={classes.dashTitle}>Employee ID: {employee.id}</Typography>
        </Box>
      }
    </div>
  );
  
};

// Global state vars
const mapStateToProps = state => { return state };
export default connect(mapStateToProps, null)(Employee);
