export default theme => ({
  toolbarRoot: {
    backgroundColor: theme.palette.background.default,
  },
  logoLink: {
    position: 'relative',
    width: theme.spacing(7),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
  },
  logoSpan: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  title: {
    flex: 1,
    display: 'none',
    textAlign: 'start',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  toolBox: {
    flex: 1,
    textAlign: 'right',
  },
  iconButton: {
    padding: 5,
  },
  dateTime: {
    fontSize: '1.05rem',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
});
