
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import firebase from 'firebase';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { useSnackbar } from 'notistack';

import styles from './updateDB.css';

const useStyles = makeStyles(styles);

function UpdateDB() {
  const classes = useStyles();

  // Local data
  const [ data, setData ] = useState({});

  // Global data
  const { enqueueSnackbar } = useSnackbar();

  function handleUploadStart(file) {
    setData({ ...data, fileName: file.name, isUploading: true, progress: 0});

    // TEMPORAL:ERROR
    console.log(data);

  }

  function handleUploadProgress(progress) {
    setData({ ...data, progress: progress});

    // TEMPORAL:ERROR
    console.log(data);

  }
  
  function handleUploadError(error) {
    setData({ ...data, isUploading: false});
    enqueueSnackbar('Error: Uploading Excel file: \'' + error + '\'', {variant: 'error',});

    // TEMPORAL:ERROR
    console.log(error);

  }

  function handleUploadSuccess(fileName) {
    setData({ ...data, isUploading: false, progress: 100});
    enqueueSnackbar('Excel file uploaded successfully in the Bucket: \'' + fileName + '\'', {variant: 'success',});

    // TEMPORAL
    processExcelFile(fileName);

  } 

  // TODO:ADJUST RULES FOR SECURYING BUCKETS IN FIREBASE

  const uploadFileName = 'update-db-' + new Date().toISOString() + '.xlsx';

  // Render the component
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Admin / UpdateDB</h2>
      <form noValidate autoComplete="off">
        <CustomUploadButton
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          name="db-update"
          filename={uploadFileName}
          storageRef={firebase.storage().ref("db-update")}
          onUploadStart={handleUploadStart}
          onProgress={handleUploadProgress}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
          Upload Excel file
        </CustomUploadButton>
      </form>
    </div>
  );
};

// TEMPORAL: WHY DON'T WORK?
/*
{data.fileName &&
  <Typography>Excel file: {data.fileName}</Typography>
}
{data.isUploading && <p>Progress: {data.progress}</p>}
*/


// Global state vars
const mapStateToProps = state => { return state };
export default connect(mapStateToProps, null)(UpdateDB);


// TEMPORAL

// TODO: CORS Policies: https://firebase.google.com/docs/storage/web/download-files

function processExcelFile(fileName) {

  // TEMPORAL
  const pathReference = firebase.storage().ref('db-update/' + fileName);
  pathReference.getDownloadURL().then(function(url) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function(event) {
      var blob = xhr.response;

      console.log(blob);

    };
    xhr.open('GET', url);
    xhr.send();
  }).catch(function(error) {
  
    console.log('---------------------->'+error);

  });

};

