
import constants from '../app/constants';

export default function loadMessages(newLanguage) {
  let messages;
  let language = newLanguage || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
  let langNoRegion = language.toLowerCase().split(/[_-]+/)[0];
  try {
    messages = require(`./${langNoRegion}.json`);
  } catch (e) {
    langNoRegion = constants.APP_DEF_LANGUAGE.toLowerCase().split(/[_-]+/)[0];
    messages = require(`./${langNoRegion}.json`);
    console.error(`Language file for '${language}' not found, using default: ${langNoRegion}`);
  }
  return { language, messages };
}
