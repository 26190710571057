
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { CssBaseline, makeStyles } from '@material-ui/core';

import styles from './app.css';
import Loader from '../components/loader/loader';
import MainHeader from '../components/main-header/mainHeader';

const userStyles = makeStyles(styles);

function App({ children }) {
  const classes = userStyles();
  const isLoading = useSelector(state => state.loading);

  // TEMPORAL
  //const employee = useSelector(state => state.employee);

  return (
    <React.Fragment>
      <CssBaseline />
      <MainHeader />
      <div className={classes.content}>
        { children }
      </div>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};

const mapStateToProps = state => { return { loading: state.loading } };

export default connect(mapStateToProps, null)(App);
