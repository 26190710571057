
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from '../pages/home/home';
import Access from '../pages/access/access';
import Clean from '../pages/clean/clean';
import Login from '../pages/auth/login';
import Logout from '../pages/auth/logout';
import Admin from '../pages/admin/admin';
import UpdateDB from '../pages/admin/updateDB';
import Report from '../pages/admin/report';
import GetQR from '../pages/admin/getQR';
import NotFound from '../pages/not-found/notFound';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/'><Redirect to='/home' /></Route>
      <Route exact path='/home'><Home /></Route>
      <Route exact path='/access'><Access /></Route>
      <Route exact path='/clean'><Clean /></Route>
      <Route exact path='/login'><Login /></Route>
      <Route exact path='/logout'><Logout /></Route>
      <Route exact path='/admin'><Admin /></Route>
      <Route exact path='/update-db'><UpdateDB /></Route>
      <Route exact path='/report'><Report /></Route>
      <Route exact path='/get-qr'><GetQR /></Route>
      <Route exact path='*'><NotFound /></Route>
    </Switch>
  );
};

export default Routes;