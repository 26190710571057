
import React from 'react';
import { makeStyles, Grid, Card, CardContent, Typography, Box } from '@material-ui/core';

import styles from './home.css';
import Employee from '../../components/employee/employee';
import DBData from '../../data/data';

const useStyles = makeStyles(styles);

function Home() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {DBData.infoMessage &&
          <Grid item xs={12}>
            <Card variant="outlined" className={classes.card}>
              <CardContent>
                <Typography>{DBData.infoMessage}</Typography>
              </CardContent>
            </Card>
          </Grid>
        }  
        <Grid item xs={12}>
          <Employee />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="primary.main" className={classes.dash}>
            <h3 className={classes.dashTitle}>Office</h3>
            <div className={classes.dashValues}>12/42</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="secondary.main" className={classes.dash}> 
            <h3 className={classes.dashTitle}>Group A</h3>
            <div className={classes.dashValues}>7/12</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="secondary.main" className={classes.dash}>
            <h3 className={classes.dashTitle}>Group B</h3>
            <div className={classes.dashValues}>11/12</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}> 
          <Box color="primary.contrastText" bgcolor="secondary.main" className={classes.dash}>
            <h3 className={classes.dashTitle}>Group C</h3>
            <div className={classes.dashValues}>5/14</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.primary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Kitchen</h3>
            <div className={classes.dashValues}>1/2</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.primary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Coffe corner</h3>
            <div className={classes.dashValues}>0/2</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="success.main" className={classes.dash}>
            <h3 className={classes.dashTitle}>Men's toilet</h3>
            <div className={classes.dashValues}>1/3</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="success.main" className={classes.dash}>
            <h3 className={classes.dashTitle}>Women's toilet</h3>
            <div className={classes.dashValues}>0/3</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.secondary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Meeting room 1</h3>
            <div className={classes.dashValues}>1/4</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.secondary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Meeting room 2</h3>
            <div className={classes.dashValues}>0/4</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.secondary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Meeting room 3</h3>
            <div className={classes.dashValues}>1/3</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.secondary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Meeting room 4</h3>
            <div className={classes.dashValues}>1/3</div>
          </Box>
        </Grid>
      </Grid>
    </div>  
  );
};

export default Home;
