/* 
employee: 
  - id
  - home_office

place:
  - id
  - max_ppl
  - current_ppl
  - clean

assignation
  - employee.id
  - place.id
  - date
*/


// TEMPORAL: MOCKED DATA
const DbData = {
    infoMessage: 'Global info message. Show only if info message.',
    employee: [
        {id: 'LDI-1001', home_office: true},
        {id: 'LDI-1002', home_office: false},
        {id: 'EXT-001', home_office: true},
    ],
    admin: {id: 'ADM-001'},
    place: [
        // Shared
        {id: 'Office', max: 42, current: 12, clean: true, style: {bgcolor: 'primary.main'}},
        {id: 'Group A', max: 12, current: 7, clean: false, style: {bgcolor: 'secondary.main'}},
        {id: 'Group B', max: 12, current: 11, clean: true, style: {bgcolor: 'secondary.main'}},
        {id: 'Group C', max: 14, current: 5, clean: false, style: {bgcolor: 'secondary.main'}},
        {id: 'Kitchen', max: 2, current: 1, clean: true, style: {bgcolor: 'text.primary'}},
        {id: 'Coffe corner', max: 2, current: 0, clean: true, style: {bgcolor: 'text.primary'}},
        {id: 'Men\'s toilet', max: 3, current: 1, clean: true, style: {bgcolor: 'success.main'}},
        {id: 'Women\'s toilet', max: 3, current: 0, clean: true, style: {bgcolor: 'success.main'}},
        {id: 'Metting room 1', max: 4, current: 1, clean: true, style: {bgcolor: 'textsuccess.main'}},

        // Personal
        /*
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.secondary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Meeting room 1</h3>
            <div className={classes.dashValues}>1/4</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="text.secondary" className={classes.dash}>
            <h3 className={classes.dashTitle}>Meeting room 2</h3>
            <div className={classes.dashValues}>0/4</div>
          </Box>
        </Grid>
        */


    ],
};

export default DbData;
