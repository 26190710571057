
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { QRCode } from 'react-qr-svg';

import styles from './getQR.css';

const useStyles = makeStyles(styles);

function GetQR() {
  const classes = useStyles();

  // TEMPORAL
  const httpHost = window.location.origin;
  const actionList = {
    home: {url: '/home', fullURL: httpHost + '/home'},
    access: {url: '/access', fullURL: httpHost + '/access'},
    clean: {url: '/clean', fullURL: httpHost + '/clean'},
    login: {url: '/login', fullURL: httpHost + '/login'},
    logout: {url: '/logout', fullURL: httpHost + '/logout'},
    admin: {url: '/admin', fullURL: httpHost + '/admin'},
    updateDB: {url: '/update-db', fullURL: httpHost + '/update-db'},
    report: {url: '/report', fullURL: httpHost + '/report'},
    getQR: {url: '/get-qr', fullURL: httpHost + '/get-qr'}
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Admin / Get QR</h2>

      <h3 className={classes.subtitle}>Employee</h3>
      <table className={classes.table}>
        <tr>
          <th>Action</th>
          <th>URL</th>
        </tr>
        <tr>
          <td>Home</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.home.fullURL} /></div>
            <div><Link to={actionList.home.url}>{actionList.home.fullURL}</Link></div>
          </td>
        </tr>
        <tr>
          <td>Access</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.access.fullURL} /></div>
            <div><Link to={actionList.access.url}>{actionList.access.fullURL}</Link></div>
          </td>
        </tr>
        <tr>
          <td>Clean</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.clean.fullURL} /></div>
            <div><Link to={actionList.clean.url}>{actionList.clean.fullURL}</Link></div>
          </td>
        </tr>
      </table>

      <h3 className={classes.subtitle}>Admin</h3>
      <table className={classes.table}>
        <tr>
          <th>Action</th>
          <th>URL</th>
        </tr>
        <tr>
          <td>Login</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.login.fullURL} /></div>
            <div><Link to={actionList.login.url}>{actionList.login.fullURL}</Link></div>
          </td>
        </tr>
        <tr>
          <td>Logout</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.logout.fullURL} /></div>
            <div><Link to={actionList.logout.url}>{actionList.logout.fullURL}</Link></div>
          </td>
        </tr>
        <tr>
          <td>Admin</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.admin.fullURL} /></div>
            <div><Link to={actionList.admin.url}>{actionList.admin.fullURL}</Link></div>
          </td>
        </tr>
        <tr>
          <td>Admin / Update DB</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.updateDB.fullURL} /></div>
            <div><Link to={actionList.updateDB.url}>{actionList.updateDB.fullURL}</Link></div>
          </td>
        </tr>
        <tr>
          <td>Admin / Report</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.report.fullURL} /></div>
            <div><Link to={actionList.report.url}>{actionList.report.fullURL}</Link></div>
          </td>
        </tr>
        <tr>
          <td>Admin / Get QR</td>
          <td>
            <div><QRCode style={{ width: 256 }} value={actionList.getQR.fullURL} /></div>
            <div><Link to={actionList.getQR.url}>{actionList.getQR.fullURL}</Link></div>
          </td>
        </tr>
      </table>  

    </div>
  );
};

export default GetQR;
