
import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';

import styles from './clean.css';

const useStyles = makeStyles(styles);
const place= [
  // Shared
  {id: 'to_clean[0].id', max: 42, current: 12, clean: true, style: {bgcolor: 'primary.main'}},
  {id: 'Group A', max: 12, current: 7, clean: false, style: {bgcolor: 'secondary.main'}},
  {id: 'Group B', max: 12, current: 11, clean: true, style: {bgcolor: 'secondary.main'}},
  {id: 'Group C', max: 14, current: 5, clean: false, style: {bgcolor: 'secondary.main'}},
  {id: 'Kitchen', max: 2, current: 1, clean: true, style: {bgcolor: 'text.primary'}},
  {id: 'Coffe corner', max: 2, current: 0, clean: true, style: {bgcolor: 'text.primary'}},
  {id: 'Men\'s toilet', max: 3, current: 1, clean: true, style: {bgcolor: 'success.main'}},
  {id: 'Women\'s toilet', max: 3, current: 0, clean: true, style: {bgcolor: 'success.main'}},
  {id: 'Metting room 1', max: 4, current: 1, clean: true, style: {bgcolor: 'textsuccess.main'}},
]

function Clean() {
  const classes = useStyles();
  var i =0;
  var to_clean = [];
  for (i = 0; i < place.length; i++){
    if (!place[i].clean){
      to_clean.push(place[i])
    }
    console.log(place[i])
  }
  return (
    <div className={classes.root}>
        <h2 className={classes.title}>Places to clean: {to_clean.length}</h2>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="primary.main" className={classes.dash}>
            <h3 className={classes.dashTitle}>{to_clean[0].id}</h3>
            <div className={classes.dashValues}>12/42</div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box color="primary.contrastText" bgcolor="primary.main" className={classes.dash}>
            <h3 className={classes.dashTitle}>{to_clean[1].id}</h3>
            <div className={classes.dashValues}>12/42</div>
          </Box>
        </Grid>
    </div>
  );
};

export default Clean;
