
export const LOADING = 'LOADING';
export const LANGUAGE = 'LANGUAGE';
export const EMPLOYEE_IN = 'EMPLOYEE_IN';

// TEMPORAL
/*
export const actionTypes = {
  login,
  logout,
  register,
  getAll,
  delete: _delete
};
*/

export const loading = loading => ({
  type: LOADING,
  payload: { loading },
});

export const employeeIn = employee => ({
  type: EMPLOYEE_IN,
  payload: { employee },
});
