
import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

import styles from './loader.css';

const useStyles = makeStyles(styles);

function Loader({ loading }) {
  const classes = useStyles();
  return !loading ? null : (
    <div data-testid="loader" className={classes.backdrop}>
      <CircularProgress className={classes.progress} />
    </div>
  );
};

export default Loader;
