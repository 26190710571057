
export default theme => ({
  root: {
    textAlign: 'left',
  },
  dash: {
    padding: theme.spacing(2),
  },
  dashTitle: {
    fontSize: 32,
  },
});
