
import { createMuiTheme } from '@material-ui/core';
import { green, blue, amber, grey, red } from '@material-ui/core/colors';

export default createMuiTheme({
  props: { 
    MuiWithWidth: { 
      initialWidth: 'sm', 
    } 
  },
  typography: {
    useNextVariants: true,
  },
  constraints: {
    maxWidth: 640,
    drawerWidth: 280,
  },
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      contrastText: '#fff',
    },
    secondary: {
      light: green[600],
      main: green[800],
      dark: green[900],
      contrastText: '#fff',
    },
    accent: {
      light: amber[300],
      main: amber[500],
      dark: amber[700],
      contrastText: '#fff',
    },
    error: {
      light: red[400],
      main: red[500],
      dark: red[600],
      contrastText: '#fff',
    },
    border: {
      light: grey[300],
      main: grey[400],
      dark: grey[600],
      contrastText: '#fff',
    },
  },
});
