
export default theme => ({
  backdrop: {
    backgroundColor: '#0000001f',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1400,
  },
  progress: {
    left: '45%',
    position: 'fixed', 
    top: '50%',
    zIndex: 1500,
  },
});
