
import React from 'react';
import { makeStyles } from '@material-ui/core';

import styles from './access.css';

const useStyles = makeStyles(styles);

function Access() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <h2 className={classes.title}>Access</h2>
    </div>
  );
};

export default Access;
