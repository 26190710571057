
import { LOADING, LANGUAGE, EMPLOYEE_IN } from './actions';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload.loading };
    case LANGUAGE:
      return { ...state, language: action.payload.language };
    case EMPLOYEE_IN:
      return { ...state, employee: action.payload.employee };  
    default:
      return state;
  };
};
