
import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import { mdiLogin, mdiLogout } from '@mdi/js';
import Icon from '@mdi/react';
import Clock from 'react-live-clock';

import styles from './mainHeader.css';

const useStyles = makeStyles(styles);

function MainHeader({ intl }) {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(state => state.user);
  return (
    <AppBar position="fixed">
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <Link to='/' className={classes.logoLink}>
          <span className={classes.logoSpan} style={{ backgroundImage: `url(./appLogo.png)` }} />
        </Link>
        <Typography className={classes.title} variant="h6" noWrap>
          {intl.formatMessage({ id: 'app.name' })}
        </Typography>
        <div className={classes.toolBox}>
          <Clock format={'ddd, MM/DD/YYYY - HH:mm'} className={classes.dateTime} />
          {!user && 
            <IconButton className={classes.iconButton} onClick={() => history.push('/login')}>
              <Icon path={mdiLogin} size={1} />
            </IconButton>
          }
          {user && 
            <IconButton className={classes.iconButton} onClick={() => history.push('/logout')}>
              <Icon path={mdiLogout} size={1} />
            </IconButton>
          }
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default injectIntl(MainHeader);
