
export default theme => ({
  root: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  title: {
    fontSize: 50,
    fontWeight: 300,
    color: theme.palette.text.primary,
    margin: theme.spacing(6),
  },
  subtitle: {
    fontSize: 26,
  },
  table: {
    textAlign: 'center',
    width: '100%',
  },
});
