
import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

import styles from './notFound.css';

const useStyles = makeStyles(styles);

function NotFound() {
  const classes = useStyles();
  return (
    <div id="message" className={classes.root}>
        <h2 className={classes.title}>404 - Page Not Found</h2>
        <Button href="/" variant="contained" color="primary" className={classes.button}>Home</Button>
    </div>
  );
};

export default NotFound;
