
import DBData from '../data/data';

export function findEmployee(empObj) {
    let foundEmpObj = null;
    for(var ind in DBData.employee) {
        let empObjAux = DBData.employee[ind];
        if(empObjAux.id === empObj.id) {
            foundEmpObj = empObjAux;
        }
    }
    return foundEmpObj;
}

export function allowedEmployee(empObj) {

    // TEMPORAL
    return empObj.access;
    
}