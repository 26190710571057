
export default theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 50,
    fontWeight: 300,
    color: theme.palette.text.primary,
    margin: theme.spacing(6),
  },
  card: {
    textAlign: 'left',
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',    
  },
  dash: {
    textAlign: 'left',
    borderRadius: 10,
  },
  dashTitle: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginLeft: theme.spacing(1),
    fontSize: 30,
  },
  dashValues: {
    textAlign: 'right',
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(2),
    fontSize: 36,
  },
});
