
import { createStore } from 'redux';

import rootReducer from './reducer';

function loadState(key) {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Error while loading ' + key + ' from local storage: ' + err);
    return undefined;
  }
};

export function saveState(key, data) {
  try {
    const serializedState = JSON.stringify(data);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error('Error while saving ' + key + ' on local storage: ' + err);
  }
};

export default function store(initialState, loadLocal=true) {
  const localState = loadLocal ? loadState('state') : {};
  return createStore(rootReducer, { ...initialState, ...localState });
};
