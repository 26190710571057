
const firebaseConfig = {
    apiKey: "AIzaSyB0gwtx0pefP7V4vLGxglJn-dkEC2qP8wE",
    authDomain: "office-qr-12a20.firebaseapp.com",
    databaseURL: "https://office-qr-12a20.firebaseio.com",
    projectId: "office-qr-12a20",
    storageBucket: "office-qr-12a20.appspot.com",
    messagingSenderId: "31588917189",
    appId: "1:31588917189:web:d7d43fd9e89523f601b5ac"
};

export default firebaseConfig;
